/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
html, body {
  height: 100%; }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

#site-main {
  flex-grow: 1; }

.call-out {
  color: #64ed73; }

.call-out-url-error {
  color: #d382ff; }

.call-out-overload {
  color: #fcc225; }

.call-out-maint {
  color: #0870ea; }

.error-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 3.4375rem 0; }
  .error-page__content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .error-page__content {
    max-width: 62.5rem; }
  .error-page__heading {
    font-family: 'Haas Grot Text RB Web', Arial, Helvetica, sans-serif;
    font-weight: 700;
    display: block;
    line-height: 1.1;
    font-size: 1.75rem;
    margin-bottom: 0.9375rem; }
    @media (min-width: 768px) {
      .error-page__heading {
        font-size: 4.4375rem;
        margin-bottom: 2.1875rem; } }
  .error-page__text {
    font-family: 'Haas Grot Text R Web', Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    margin: 0.9375rem 0; }
    @media (min-width: 768px) {
      .error-page__text {
        font-size: 2.375rem;
        margin: 2.1875rem 0; } }
  .error-page__btn {
    max-width: 21.875rem; }
  .error-page__error-details {
    word-break: break-word; }
